// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-news-1 {
  width: 60%;
}

.section-news-1 .card {
  width: 20rem;
}

.section-news-1 .card:hover {
  color: #327a6d;
}

.section-news-1 .btn {
  border: 1px solid #327a6d;
  color: #fff;
  background-color: transparent;
}

.section-news-1 .btn.active {
  background-color: #327a6d;
  color: white;
}

@media (max-width: 1650px) {
  .section-news-1 {
    width: 75%;
  }
}

@media (max-width: 580px) {
  .section-news-1 {
    width: 75%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/news.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".section-news-1 {\r\n  width: 60%;\r\n}\r\n\r\n.section-news-1 .card {\r\n  width: 20rem;\r\n}\r\n\r\n.section-news-1 .card:hover {\r\n  color: #327a6d;\r\n}\r\n\r\n.section-news-1 .btn {\r\n  border: 1px solid #327a6d;\r\n  color: #fff;\r\n  background-color: transparent;\r\n}\r\n\r\n.section-news-1 .btn.active {\r\n  background-color: #327a6d;\r\n  color: white;\r\n}\r\n\r\n@media (max-width: 1650px) {\r\n  .section-news-1 {\r\n    width: 75%;\r\n  }\r\n}\r\n\r\n@media (max-width: 580px) {\r\n  .section-news-1 {\r\n    width: 75%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
