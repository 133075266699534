// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section .gal-content-container {
  margin: 5rem 0 2rem 0;
  padding: 4rem 3rem 3rem 3rem;
}

.gal-content-container h1{
     color: #327a6d;
}

.gal-content-container .row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.gal-content-container .column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.gal-content-container .column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .gal-content-container .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .gal-content-container .column {
    flex: 100%;
    max-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/gallery.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,4BAA4B;AAC9B;;AAEA;KACK,cAAc;AACnB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,cAAc;AAChB;;AAEA,2DAA2D;AAC3D;EACE,SAAS;EACT,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,WAAW;AACb;;AAEA,0EAA0E;AAC1E;EACE;IACE,SAAS;IACT,cAAc;EAChB;AACF;;AAEA,uGAAuG;AACvG;EACE;IACE,UAAU;IACV,eAAe;EACjB;AACF","sourcesContent":["section .gal-content-container {\r\n  margin: 5rem 0 2rem 0;\r\n  padding: 4rem 3rem 3rem 3rem;\r\n}\r\n\r\n.gal-content-container h1{\r\n     color: #327a6d;\r\n}\r\n\r\n.gal-content-container .row {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  padding: 0 4px;\r\n}\r\n\r\n/* Create four equal columns that sits next to each other */\r\n.gal-content-container .column {\r\n  flex: 25%;\r\n  max-width: 25%;\r\n  padding: 0 4px;\r\n}\r\n\r\n.gal-content-container .column img {\r\n  margin-top: 8px;\r\n  vertical-align: middle;\r\n  width: 100%;\r\n}\r\n\r\n/* Responsive layout - makes a two column-layout instead of four columns */\r\n@media screen and (max-width: 800px) {\r\n  .gal-content-container .column {\r\n    flex: 50%;\r\n    max-width: 50%;\r\n  }\r\n}\r\n\r\n/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */\r\n@media screen and (max-width: 600px) {\r\n  .gal-content-container .column {\r\n    flex: 100%;\r\n    max-width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
